import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import Button from "../components/button"
import OwnerCard from "../components/ownerCard"
import Seo from "../components/seo"
import BreadCrumbs from "../components/breadCrumbs"
import Title from "../components/title"

const ChiSiamoPage = ({ location }) => (
  <>
    <Seo
      title="Chi siamo e Mission"
      description="Conosci la storia e la mission dello Studio commercialisti associati Benetti Filippini"
    />
    <Container>
      <BreadCrumbs location={location} />
      <Title content="Lo studio Benetti Filippini" />
      <IntroText>
        Lo studio associato Benetti – Filippini è stato fondato nel 1995 a
        Brescia da Mariacecilia Benetti e Ombretta Filippini iscritte all’Albo
        dei dottori commercialisti e Esperti contabili di Brescia dal 1989.
      </IntroText>
      <MissionContainer>
        <SubTitle>Mission</SubTitle>
        <MissionText>
          La nostra MISSION è affiancare il cliente nella comprensione delle
          regole di economia aziendale e finanziaria e delle disposizioni
          legislative, mettendo al servizio le nostre conoscenze professionali e
          gli strumenti tecnici in modo semplice e diretto. Riteniamo
          fondamentale, nella costruzione del rapporto fiduciario con il
          cliente, una comunicazione trasparente e diretta.
        </MissionText>
      </MissionContainer>
      <OwnersContainer>
        <Hide>
          <div style={{ height: "96px" }}></div>
        </Hide>
        <OwnerCard name="ceciliabenetti" />
        <Hide>
          <div style={{ height: "96px" }}></div>
        </Hide>
        <OwnerCard name="ombrettafilippini" />
      </OwnersContainer>
      <Hide>
        <div style={{ height: "96px" }}></div>
        <Link to="/contattaci">
          <Button isFullWidth primary>
            Prenota un appuntamento
          </Button>
        </Link>
      </Hide>
    </Container>
  </>
)

const Container = styled.div`
  margin: 16px 20px 128px;
  @media screen and (min-width: 1050px) {
    margin: 24px 0 192px;
    & p {
      font-size: 16px;
    }
  }
`

const SubTitle = styled.h2`
  font-size: 24px;
  margin: 48px 0 32px;
  @media screen and (min-width: 1050px) {
    width: 50%;
    margin: 128px 0 48px;
    font-size: 28px;
  }
`

const IntroText = styled.p`
  @media screen and (min-width: 1050px) {
    width: 70%;
  }
`
const MissionText = styled.p`
  @media screen and (min-width: 1050px) {
    width: 50%;
  }
`

const MissionContainer = styled.div`
  @media screen and (min-width: 1050px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-left: 250px;
  }
`

const OwnersContainer = styled.div`
  @media screen and (min-width: 1050px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 196px;
  }
`

const Hide = styled.div`
  @media screen and (min-width: 1050px) {
    display: none;
  }
`

export default ChiSiamoPage
